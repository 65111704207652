// eslint-disable-next-line
export default (CURRENT_ENV, country) => {
  const isPnp = ['se', 'fi', 'nl', 'de', 'ax'].includes(country);
  const isLatam = ['cl', 'pe'].includes(country);

  return {
    config: 'common',
    defaultJurisdiction: 'mga',
    CURRENT_ENV,
    type: isPnp ? 'pnp' : 'account',
    indexContainer: 'IndexContainerAccount',
    skinId: CURRENT_ENV === 'prod' ? 65 : 62,
    defaultLocale: 'en-US',
    defaultTitle: 'A Turbo-Fast Pay N Play Casino',
    MERCHANT_ID: CURRENT_ENV === 'prod' ? 100014018 : 100014999,
    content: {
      space: 'uyE6dnrEc31G',
      host: 'https://delivery2.objectic.io',
      access_token: 'l6QB14971gQ3uJVo66tnFMcp9gYny8SwrpKPG8vg',
      preview_host: 'https://preview.objectic.io',
      preview_access_token: 'l6QB14971gQ3uJVo66tnFMcp9gYny8SwrpKPG8vg',
      environment: CURRENT_ENV === 'prod' ? 'master' : 'stage',
    },
    games: {
      space: 'jIWXpQOLRVBo',
      host: 'https://delivery2.objectic.io',
      access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
      preview_host: 'https://preview.objectic.io',
      preview_access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
      environment: CURRENT_ENV === 'prod' ? 'master' : 'master',
      gameIdKey: CURRENT_ENV === 'prod' ? 'tg-pz' : 'tg-pz-stage',
    },
    axiosCache: {
      duration: 600, // 10 minutes
      whitelist: [
        'cdn.contentful.com',
        'delivery2.objectic.io',
        '/event-feed/jackpots',
      ],
    },
    jurisdictions: [
      {
        key: 'mga',
        name: 'turbovegas.com',
        domain: CURRENT_ENV === 'prod' ? 'www.turbovegas.com' : null,
      },
      {
        key: 'sga',
        name: 'turbovegas.com (se)',
        domain: CURRENT_ENV === 'prod' ? 'www.turbovegas.com' : null,
      },
    ],
    tgplay: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://play.turbovegas.com'
          : 'https://play-togethergaming-new-config-stage.azurewebsites.net',
      skin: 'turbovegas_pz',
      standalone: true,
    },
    aleacc: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.turbovegas.com'
          : 'https://api-stage-turbovegas.codebet.dev',
    },
    piqCashier: {
      predefinedAmounts: {
        eur: [20, 50, 100],
        cad: [20, 50, 100],
        nzd: [20, 50, 100],
        nok: [200, 500, 1000],
        jpy: [3000, 7500, 15000],
      },
      showAmountLimits: true,
      showFee: false,
      showBonusCode: true,
      theme: {
        input: {
          borderRadius: '2px',
          color: '#000',
        },
        inputbackground: {
          color: '#fff',
        },
        labels: {
          color: '#fff',
        },
        headings: {
          color: '#fff',
        },
        loader: {
          color: '#fff',
        },
        buttons: {
          color: '#ff5850',
        },
        headerbackground: {
          color: '#00abc6',
        },
        background: {
          color: '#00abc6',
        },
        cashierbackground: {
          color: 'transparent',
        },
        creditcardicons: {
          creditcardUrl: 'cc_100014037.svg',
          cvvUrl: 'cvv_100014037.svg',
          expirydateUrl: 'expire_100014037.svg',
        },
      },
      css: `
        #cashier .submit-button {
        color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 0 3px #fff, -4px 4px 0 4px #003e5d, 0 0 0 5px #003e5d;
      }
      #cashier .payment-method {
        background-color: #00abc6;
        margin-bottom: 5px;
        border: none;
      }
      #cashier .payment-method :hover{
          background-color: #00abc6;
      }
      #cashier .dialog-content {
        background-color: #00abc6;
      }
      #cashier .neteller-input-template {
        background: none;
      }
      #cashier .set-amount {
        text-align: center;
      }
      #cashier .predefinedvalues button {
        background-color: #ff5850;
        box-shadow: 0 0 0 3px #fff, -4px 4px 0 4px #003e5d, 0 0 0 5px #003e5d;
        color: #fff;
        width: 88%;
        text-align: center;
        border-radius: 0px;
        opacity: 1;

      }
      #cashier .predefinedvalues button:hover {
        background-color: #ff5850;
        color: #fff;
      }
      #cashier .predefinedvalues button.active {
        color: #fff;
      }
      #cashier .predefinedvalues-btn:first-child button {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
      }
      #cashier .predefinedvalues-btn:last-child button {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
      }
      #cashier input {
        box-shadow: 0 0 0 3px #fff, -4px 4px 0 4px #003e5d, 0 0 0 5px #003e5d !important;
        color: #000;
        background-color: #fff !important;
        border: none;
      }
      #cashier input:hover {
        background-color: #fff;
      }
      #cashier .input-container {
        padding: 0 5px;
      }
      #cashier select option {
        color: #000;
      }`,
    },
    devcode: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.paymentiq.io/paymentiq/api'
          : 'https://test-bo.paymentiq.io/paymentiq/api',
      oauth_host:
        CURRENT_ENV === 'prod'
          ? 'https://backoffice.paymentiq.io/paymentiq/oauth'
          : 'https://test-api.paymentiq.io/paymentiq/oauth',
      oauth_provider: 'trustly',
      oauth_clientid:
        CURRENT_ENV === 'prod'
          ? '6b494d8095744c8583a785cfeb6887ea'
          : 'ea57478703884e34b17eded914691a06',
      oauth_redirecturl:
        CURRENT_ENV === 'prod'
          ? 'https://www.turbovegas.com/nemid.html'
          : 'https://stage.turbovegas.com/nemid.html',

      oauth_ui_friendly: 'bethard-1',
    },
    quickDeposit: {
      enabled: false,
      host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/tg-quickdeposit.bundle.js',
      successUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/success.html',
      failureUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/failure.html',
      cancelUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/cancel.html',
    },
    metric: {
      operator: isLatam ? 'turbovegas-latam' : 'turbovegas',
      host:
        CURRENT_ENV === 'prod'
          ? isLatam
            ? 'https://turbovegas-latam.betpump.com'
            : 'https://turbovegas.betpump.com'
          : isLatam
          ? 'https://turbovegas-latam.superlivepreprod.com'
          : 'https://turbovegas.superlivepreprod.com',
    },
    freshChat: {
      token: "c44b51b7-1e7b-4df7-8009-72478339d0df",
      host: "https://bethard.freshchat.com",
      widgetUuid: "f3baa4c4-7779-4559-a63c-7a4fb79309cb",
      brand: 'turbovegas',
      appName: 'Turbovegas',
      appImage:
        CURRENT_ENV === 'prod'
          ? 'https://www.turbovegas.com/skin/images/chatavatar.png'
          : 'https://stage.turbovegas.com/skin/images/chatavatar.png',
      siteId: 'turbovegas',
      tags: [],
      config: {
        headerProperty: {
        },
        cssNames: {
          widget: 'fc_frame',
          open: 'fc_open',
        },
      },
    },

    chat: {
      provider: 'zendesk',
      host: 'https://static.zdassets.com/ekr/snippet.js?key=757066ad-188d-4b5b-8ad7-9501b4196011',
      secret:
        '9DC9F1C05BCE1B215BB934F9A2256FDA5DDBE0FF22DDE0FD8348098AD04A3CC1',
      departments:
        country === 'se'
          ? ['Turbo Vegas Svensk Support']
          : [
              'TurboVegas English Support',
              'TurboVegas Deutscher Kundenservice',
              'TurboVegas Asiakaspalvelu Suomeksi',
              'TurboVegas Norsk Kundeservice',
              'TurboVegas Soporte en Español',
              'TurboVegas Suporte Portugues',
            ],
      tags: country === 'se' ? ['turbovegas'] : ['turbovegasglobal'],
      colors: {
        theme: '#ff5850',
        launcher: '#ff5850',
        launcherText: '#ffffff',
        button: '#ff5850',
      },
    },

    imProvider: 'fasttrack',

    fastTrack: {
      brand: 'mozebra',
      config: {
        integrationVersion: 1.1,
        autoInit: true,
        inbox: {
          enable: true,
          badgeId: '#ft-crm-inbox-badge',
          navBarLogo: 'https://via.placeholder.com/200x200',
          // contactEmail: "support@example.com",
          supportLink: 'mailto:daniel@fasttrack-solutions.com',
          supportLinkText: 'customer support »',
        },
      },
      script:
        CURRENT_ENV === 'prod'
          ? 'https://crm-lib.fasttrack-solutions.com/loader/fasttrack-crm.js'
          : 'https://crm-lib-staging.fasttrack-solutions.com/loader/fasttrack-crm.js',
    },

    sendActivationCodeOnSignUp: false,
    disableActivationStep: true,

    redirectCasino: true,
    /**
     * Show sticky footer on desktop
     */
    showStickyFooter: false,
    simpleHeader: true,

    /**
     * Show sticky footer sign up CTA
     */
    stickyFooterButton: false,

    /*
     * Choose if support button in header/dashboard should navigate to page or open support widget
     */
    openSupportWidget: {
      header: false,
      sidebar: true,
    },

    /**
     * Casino options
     * @property layout Game grid layout (slider, grid)
     * @property searchStyle Show casino categories (categories, noCategories)
     */
    casino: {
      layout: 'grid',
      casinoCategoryMenuStyle: 'categories',
      liveCasinoData: true,
    },

    /**
     * Language selector options
     * @property type - Type of selector (dropdown, list)
     * @property position - Language selector position (header, footer)
     */
    languageSelector: {
      type: 'list',
      position: 'footer',
    },

    /**
     * Options for communication consent checkbox on details step.
     */
    communicationConsent: {
      enabled: true,
      values: [
        'BonusEmails',
        'BonusSMSs',
        'NewsEmails',
        'NewsSMSs',
        'Phone',
        'Casino',
        'Sportsbook',
      ],
    },
    /**
     * Visibility configuration for user registration.
     * @description
     * Current available elements for the array: ['email', 'phone']
     */
    userFields: ['email', 'phone'],

    /**
     * How the index page features should be displayed
     * @description
     * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
     * Default :
     * desktop: 'row',
     * mobile: 'slider'
     */
    indexFeatures: {
      desktop: 'row',
      mobile: 'slider',
    },

    /**
     * If the sign in/fetch balance button should be displayed in header or base page.
     * @description
     * Default is true which means it should be in header.
     * showFetchBalanceIndexHeader: true
     */
    showFetchBalanceIndexHeader: isPnp,

    globalNavContainer: false,

    products: ['casino', 'payments', 'sportsbook'],

    showBonusSelector: true,

    hideOnScroll: {
      footer: false,
    },

    preGameView: {
      signedIn: true,
      signedOut: true,
    },

    visitedCookieName: 'tg-page-visited',
    visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
    gameHistoryName: 'tg-game-history',
    btagCookie: 'tg-btag',
    defaultCurrency: 'EUR',

    GTM_ID: 'GTM-K95JQF3',

    redirects: [
      {
        source: /^\/unsub/,
        target: () =>
          isPnp
            ? '/fetch-balance?ref_url=dashboard/subscriptions'
            : '/?ref_url=%23dashboard%2Fsubscriptions#sign-in',
      },
      {
        source: '/deposit',
        target: () => (isPnp ? '/start-playing' : '/#deposit'),
      },
      {
        source: '/withdraw',
        target: () => '/#withdraw',
      },
      {
        source: /^\/(sign-in|login)$/,
        target: () => (isPnp ? '/fetch-balance' : '/#sign-in'),
      },
      {
        source: '/sign-up',
        target: () => '/#sign-up',
      },
      {
        source: '/dashboard',
        target: () => '/#dashboard',
      },
      {
        source: /^\/(dashboard\/activate|activate)$/,
        target: () => '/#dashboard/activate',
      },
      {
        source: '/dashboard/responsible-gaming',
        target: () => '/#dashboard/responsible-gaming',
      },
      {
        source: '/dashboard/subscriptions',
        target: () => '/#dashboard/subscriptions',
      },
      {
        source: '/dashboard/verify',
        target: () => '/#dashboard/verify',
      },
      {
        source: '/dashboard/bonus',
        target: () => '/#dashboard/bonus',
      },
      {
        source: '/dashboard/history',
        target: () => '/#dashboard/history',
      },
      {
        source: '/dashboard/messages',
        target: () => '/#dashboard/messages',
      },
      {
        source: '/dashboard/edit-profile',
        target: () => '/#dashboard/edit-profile',
      },
      {
        source: '/dashboard/change-password',
        target: () => '/#dashboard/change-password',
      },
      {
        source: '/sbtech/sbtech.js',
        target: () => '/sbtech.js',
      },
      {
        source: '/sports',
        target: () => '/casino',
      },
    ],
    /**
     * @param {Request} [req] Provide the request object if this function is being used on the server
     * @returns {(string|boolean)}
     */
    cookieDomain: (req) => {
      if (!__CLIENT__ && !req) return false;
      return (__CLIENT__ ? window.location.href : req.get('host')).includes(
        'turbovegas.com'
      )
        ? '.turbovegas.com'
        : false;
    },
  };
};
